<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>

    <div @click="handleBack">
      <i class="el-icon-arrow-left"></i>
      <span>返回订单列表</span>
    </div>

    <div class="pay_order_status">
      <h3>订单状态：等待买家付款</h3>
      <el-steps :active="1" finish-status="success" align-center>
        <el-step title="买家下单"></el-step>
        <el-step title="买家付款"></el-step>
        <el-step title="发货"></el-step>
        <el-step title="买家确认收货"></el-step>
      </el-steps>
    </div>

    <p class="pay_order_msg_p">订单信息</p>
    <div class="pay_order_msg">
      <div class="pay_order_trading_msg">
        <h5>交易信息</h5>
        <p>
          订单编号：<span>{{ orderDataRow.orderIdcopy }}</span>
        </p>
        <p>
          创建时间：<span>{{ orderDataRow.createTime }}</span>
        </p>
      </div>
      <div class="pay_order_trading_msg">
        <h5>买家信息</h5>
        <p>
          昵称：<span>{{ orderDataRes.realName }}</span>
        </p>
        <p>
          账号：<span>{{ orderDataRes.phone }}</span>
        </p>
        <el-button
          type="text"
          style="color: #67c23a"
          v-clipboard:copy="messageBuy"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制买家信息
        </el-button>
      </div>
      <div class="pay_order_trading_msg">
        <h5>物流信息</h5>
        <p>
          收货人：<span>{{ orderDataRes.contacts }}</span>
        </p>
        <p>
          区号：<span>{{ areaCode }}</span>
        </p>
        <p>
          收货人电话：<span>{{ orderDataRes.number }}</span>
        </p>
        <p>
          收货地址：<span
            >{{ orderDataRes.province }}{{ orderDataRes.city
            }}{{ orderDataRes.district }}{{ orderDataRes.address }}</span
          >
        </p>
        <el-button
          type="text"
          style="color: #67c23a"
          v-clipboard:copy="messageExpress"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制物流信息
        </el-button>
      </div>
    </div>

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column prop="iconUrl" label="商品图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="skuPrice" label="商品价格"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <el-table-column prop="skuAttr" label="商品规格"> </el-table-column>
        <!-- <el-table-column prop="status" label="优惠价格" align="center">
          <template slot-scope="scope">
            ¥{{ scope.row.collection }}<br />cple积分抵扣
          </template>
        </el-table-column> -->
        <el-table-column prop="payType" label="实收款" align="center">
          <template slot-scope="scope">
            ¥{{
              scope.row.payType == 1
                ? scope.row.amount
                : scope.row.payType == 7
                ? scope.row.amountUsdt
                : scope.row.payType == 8
                ? scope.row.amountToken
                : scope.row.payType == 10
                ? scope.row.p95Amount
                : 0
            }}<br />(快递：0.00)
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      areaCode: "",
      messageBuy: "",
      messageExpress: "",
      orderDataRow: [],
      orderDataRes: [],

      tableData: [],

      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "趣宝盒预售",
        },
        {
          name: "待付款订单",
        },
        {
          name: "查看详情",
        },
      ], // 面包屑数据
    };
  },

  mounted() {
    this.orderDataRow = JSON.parse(localStorage.getItem("orderYSDetail"));
    this.tableData.push(JSON.parse(localStorage.getItem("orderYSDetail")));
    let orderid = this.orderDataRow.orderIdcopy;

    showLoading();
    const opt = {
      url: reqUrl.getMallAddressInfo,
      method: "POST",
      params: JSON.stringify({
        orderId: orderid,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          this.orderDataRes = res.data.data;
          this.areaCode = res.data.data.phoneArea;

          this.messageBuy = `买家昵称：${this.orderDataRes.realName} 买家账号：${this.orderDataRes.phone}`;

          this.messageExpress = `${this.orderDataRes.contacts} ${this.orderDataRes.phoneArea} ${this.orderDataRes.number} ${this.orderDataRes.province}${this.orderDataRes.city}${this.orderDataRes.district}${this.orderDataRes.address}`;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      this.$router.push("/boxPresellVaitOrder");
      localStorage.removeItem("orderYSDetailRow");
    },

    onCopy() {
      this.$message.success("复制地址成功");
    },
    onError() {
      this.$message.error("复制地址失败");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../index.scss";
</style>
